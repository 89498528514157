import React, {useState, useEffect} from "react";
import firebase from "services/firebase";
import useUI from "hooks/ui.hook";
import { Spinner } from "lib/components";
import moment from "moment";

import PropTypes from "prop-types";
import image from "assets/images/home/activity.png";
import styles from "assets/scss/components/homeWidgets/todayActivities.module.scss";
import { Calendar, MapPin, Tag, Users, X } from "react-feather";
import { InfoCard, InfoCardHeader, InfoCardBody } from "lib/components/InfoCard";

const firestore = firebase.firestore;

const TodayActivities = () => {
  const [ui] = useUI();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    (async () => {
      try {
        let start = moment(new Date()).startOf("days").toDate();
        let end = moment(new Date()).endOf("days").toDate();
        
        await firestore().collection("establishments").doc(ui.user.establishment)
          .collection("blocks").doc("planning")
          .collection("categories")
          .onSnapshot((res)=> {
            let categories = {};
            res.forEach(d => categories[d.id] = d.data());
            setCategories(categories);
          });

        await firestore().collection("establishments").doc(ui.user.establishment)
          .collection("blocks").doc("planning")
          .collection("events")
          .where("isPublished", "==", true)
          .where("start", ">", start).where("start", "<", end)
          .orderBy("start", "asc")
          .onSnapshot((res)=> {
            let _activities = [];
            res.forEach(d => _activities.push({uid: d.id, ...d.data()}));
            setActivities(_activities);
          });
       
      } catch (e) {
        console.error(e.message);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {/* <h5 style={{fontWeight:600, fontSize:18, textAlign: "center"}}>les activités du jour</h5> */}
      </div>
      <div className={styles.body}>
        {/* <img src={image} /> */}
        {/* <h5>Activités du jour</h5> */}
        {loading ? 
          <Spinner />
          :
          activities.filter((e) => e.isPublished || e.isPublished === undefined || e.isPublished === null).length === 0 ?
            // eslint-disable-next-line react/no-unescaped-entities
            <p>Aucune activité aujourd'hui</p>
            :
            <>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {activities.length > 0 ? <h5>Aujourd'hui</h5> : null}
              <div className={styles.activitiesContainer}>
                {activities.map(i => 
                  i.isPublished || i.isPublished === undefined || i.isPublished === null ?
                  <RenderActu event={i} categories={categories} key={i.uid}/> : null
                )}
              </div>
            </>
          }
      </div>
    </div>
  );
};

const RenderActu = ({event, categories}) => {

  return (
    <InfoCard>
      <InfoCardHeader color={categories[event.categoryId]?.color ?? "rgb(242, 153, 74)"}>
          {categories[event?.categoryId]?.smiley ? 
              <img src={categories[event.categoryId]?.smiley} style={{width: 25, height: 25}}></img> : <div></div>}
              <span className={styles.category}>{categories[event.categoryId]?.title}</span>
              <div className={styles.empty}></div>
      </InfoCardHeader>
      <InfoCardBody image={event.image} title={event.title}>
            <div className={styles.infoBar} >
                <div className={styles.infoContent} style={{gap: 10}}>
                  <Calendar size={22} />
                  <span className={styles.hours}>{moment(event.start.seconds * 1000).format("HH:mm")} - {moment(event.end.seconds * 1000).format("HH:mm")}</span>
                </div>
                {event.place ? 
                  <div className={styles.infoContent} style={{gap: 10}}>
                    <MapPin size={22} />
                    <span className="text-gray-800 text-xl font-italic">{event.place}</span>
                  </div>
                : null}
                {event.maxParticipants ?
                  <div className={styles.infoContent} style={{gap: 10}}>
                    <Users size={22} />
                    <span className="text-gray-800 text-xl font-italic">{event.reservation?.length ?? 0} / {event.maxParticipants}</span>
                  </div>
                : null}
              </div>
      </InfoCardBody>
    </InfoCard>
  );
};


RenderActu.propTypes = {
  event: PropTypes.object,
  categories: PropTypes.object,

};

export default TodayActivities;