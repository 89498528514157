import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Button,
  Checkbox,
  FormGroup,
  Select
} from "lib/components";
import moment from "moment";
import { useMenu } from "../../../router_context";
import { DishCard, SupplementRestaurant } from "../../../router_components";
import PropTypes from "prop-types";
import { sortDishesWithMenu } from "pages/Major/Menu/helpers/operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import imgEntree from "../Assets/Images/icons8-greek-salad-96.png";
import imgPlat from "../Assets/Images/icons8-thanksgiving-96.png";
import imgCocotte from "../Assets/Images/icons8-cooking-pot-96.png";
import imgGarniture from "../Assets/Images/icons8-carrot-96.png";
import imgDessert from "../Assets/Images/icons8-cake-96.png";
import imgSupplement from "../Assets/Images/icons8-cheese-96.png";

import styles from "../../../Basic/Assets/scss/categorySelector.module.scss";
import useFeature from "../../../../../../hooks/useFeature";

const imgMap = { "entrée": imgEntree, "plat": imgPlat, "cocotte": imgCocotte, "garniture": imgGarniture, "dessert": imgDessert, "supplément": imgSupplement };

const CategorySelector = (props) => {
  const { page, dataReservation, updateSelection } = props;

  const [ui] = useUI();
  const [ctx, dispatch] = useMenu();
  const template = ui.establishment.template;
  const dataDay = ctx?.data[ctx?.selectedDate];
  const _repas = dataDay[ctx.modalInfos.repas];
  const isCocotte = moment(ctx.selectedDate).weekday() === 6 && ctx.modalInfos.repas === "Déjeuner";
  const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
  const hasFeatureRestaurant = useFeature("restaurant");


  const handleClick = (cat, dishId) => {
    if (dataReservation[cat] === dishId)
      updateSelection(cat, null);
    else
      updateSelection(cat, dishId);
  };

  const getDishes = useCallback((category) => {

    if (category === "supplément" || category === "dessert") {
      if (!_repas) return [];
      let _category = _repas["dessert"]; if (!_category) return [];

      const _plats = sortDishesWithMenu(template, ctx.modalInfos.repas, Object.entries(_category));

      return _plats;

    } else {
      if (!_repas) return [];
      let _category = _repas[category]; if (!_category) return [];

      const _plats = sortDishesWithMenu(template, ctx.modalInfos.repas, Object.entries(_category));
      return _plats;
    }

  }, [dataDay, dataReservation, ctx]);

  const isDishDisabled = useCallback((cat, dishId) => {
    if (cat === "supplément") {

      // on vérifie si ce plat est un dessert et si un dessert est déjà réservé dans la categ dessert.
      if (Object.keys(_repas["dessert"]).includes(dataReservation["dessert"]) && Object.keys(_repas["dessert"]).includes(dishId)) {
        return true;
      } else {
        if (dataReservation["dessert"] === dishId) {
          return true;
        }
      }

      return false;
    } else {
      return false;
    }
  }, [dataDay, dataReservation, ctx]);

  const isCategoryDisabled = useCallback((category) => {
    if (ctx.modalInfos.repas === "Dîner") {
      switch (category) {
        case "entrée":
          if (dataReservation["plat"] && dataReservation["dessert"]) return true;
          else return false;
        case "plat":
          if (dataReservation["entrée"] && dataReservation["dessert"]) return true;
          else return false;
        case "dessert":
          if (dataReservation["entrée"] && dataReservation["plat"]) return true;
          else return false;
        case "supplément":
          if (dataReservation["entrée"] && dataReservation["plat"] && dataReservation["dessert"]) return true;
          else return false;
        default:
          break;
      }
    } else {
      return false;
    }
  }, [dataDay, dataReservation, ctx]);

  const isSelected = (dataReservation, cat, dishId) => {
    
    if(["garniture", "dessert"].includes(cat) && dataReservation && dataReservation[cat] && dataReservation[cat].includes(dishId)) return true;
    if(dataReservation && dataReservation[cat] && dataReservation[cat] === dishId) return true;
    return false;
  };

  return (
    <div className={styles.container}>


      {[...template[ctx.modalInfos.repas].categories].map((cat, indx) => (
        (isCocotte && ["garniture", "dessert"].includes(cat)) || !isCocotte ?
          dataDay && dataDay[ctx.modalInfos.repas] && getDishes(cat).length > 0 ?
            <div key={indx} className={styles.categComponent}>
              <div className={styles.categTitle}>
                <div className={styles.img}>
                  <img src={imgMap[isCocotte && cat === "plat" ? "cocotte" : cat]} />
                </div>
                <div>
                  {isCocotte && cat === "plat" ? "cocotte" : cat}
                </div>
              </div>
              <div className={styles.categChoices}>
                {

                  getDishes(cat).map(([dishId, dish], indx2) => (
                    <div key={indx2}>
                      <DishCard disabled={isCategoryDisabled(cat) || isDishDisabled(cat, dishId)} selected={isSelected(dataReservation, cat, dishId)} category={cat} dishId={dishId} dishInfo={dish} handleClick={(dishId) => handleClick(cat, dishId)} />
                    </div>
                  ))
                }
              </div>
            </div>
            : null
          : null
      ))}
      {hasFeatureRestaurant && <div className={styles.categComponent}>
        <div className={styles.categTitle} style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            Suppléments Restaurant
            <div style={{cursor: "pointer", color: "#5F0D6D"}} onClick={() => setIsModalSupplementOpen(true)}>
                <FontAwesomeIcon
                    icon={faEdit}
                    size="1x"
                    color="#5F0D6D"
                />
                Éditer
            </div>
        </div>
        <div className={styles.categChoices}>
            {
              <SupplementRestaurant 
                  isOpen={isModalSupplementOpen}
                  onClose={() => setIsModalSupplementOpen(false)}
                  onSubmit={(supplement) => {
                      console.log(supplement)
                      updateSelection("suppléments restaurant", supplement);
                  }} 
                  supplements={dataReservation["suppléments restaurant"] ?? []}
              />
            }
        </div>
      </div>}
    </div>
  );
};


CategorySelector.propTypes = {
  dataReservation: PropTypes.object,
  updateSelection: PropTypes.func,
  page: PropTypes.number,
};


export default CategorySelector;
