import firebase from "firebase";
const firestore = firebase.firestore;

export const getGroupementSupplementCategories_rt = ({ groupementId }, cb, err) => {
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .onSnapshot(snap => {
      cb(snap.data()?.supplementsCategories ?? []);
    }, err ?? (() => {}));

  return subscribe;
}

export const getAllEtabSupplements_rt = ({etabId}, cb, err) => {
  const subscribe  = firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .onSnapshot(snap => {
      const data = snap.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      }));
      cb(data);
    }, err ?? (() => {}));

  return subscribe;
};

export const getGroupementSupplements_rt = ({ groupementId }, cb, err) => {
const subscribe  = firestore()
.collection("groupements")
.doc(groupementId)
.collection("blocks")
.doc("restaurant")
.collection("supplements")
// .orderBy("date", "desc")
// .limit(1)
.onSnapshot(snap => {
  const data = snap.docs.map(doc => ({
    uid: doc.id,
    ...doc.data()
  }));
  cb(data);
}, err ?? (() => {}));

return subscribe;
}