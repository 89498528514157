import dayjs from "dayjs";

// Prise en compte de l'heure en plus du nombre de jour
const dateWall = (ctx, template, repas) => {
  const hour = template[repas].heure;
  const dateWall = dayjs(ctx?.selectedDate)
    .set("hour", hour)
    .set("minute", 0)
    .subtract(ctx.delay ?? 0, "d")
    .isAfter(dayjs());
  return dateWall;
}

export default dateWall