import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@/lib/components";
import styles from "../Assets/scss/supplementRestaurant.module.scss";
import useUI from "hooks/ui.hook";
import { getAllEtabSupplements_rt, getGroupementSupplements_rt, getGroupementSupplementCategories_rt } from "@/services/ressources/supplement_restaurant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const SupplementRestaurant = (props) => {
  const { isOpen, onClose, onSubmit, supplements } = props;
  const [ui] = useUI();
  const [etabSupplements, setEtabSupplements] = useState([]);
  const [groupementSupplements, setGroupementSupplements] = useState([]);
  const [groupementSupplementsCategories, setGroupementSupplementsCategories] = useState([]);
  const [selectedSupplement, setSelectedSupplement] = useState([]);

  const applyKeys = (categories) => {

    const applyLevelKeys = (categories, level) => {
      return categories.map((category, index) => {
        return {
          ...category,
          key: level + "-" + index,
          children: category.children ? applyLevelKeys(category.children, level + "-" + index) : []
        }
      })
    }

    return applyLevelKeys(categories, 0);
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedSupplement(supplements ?? [])
    }
  }, [isOpen])


  useEffect(() => {
    if (ui?.establishment?.uid) {
      const subscribe = getAllEtabSupplements_rt({ etabId: ui?.establishment?.uid }, (d) => setEtabSupplements(d));
      return subscribe;
    }
  }, [ui]);

  useEffect(() => {
    if (ui?.groupement?.uid) {
      const subscribe = getGroupementSupplements_rt({ groupementId: ui?.groupement?.uid }, (d) => setGroupementSupplements(d), (e) => console.log(e));
      return subscribe;
    }
  }, [ui]);

  useEffect(() => {
    if (ui?.groupement?.uid) {
      const unsubscribe = getGroupementSupplementCategories_rt(
        { groupementId: ui?.groupement?.uid },
        (d) => setGroupementSupplementsCategories(applyKeys(d))
      );
      return unsubscribe;
    }
  }, [ui]);

  const flatCategories = useMemo(() => {
    const flattenCategories = (categories) => {
      let flatCategories = [];
      for (const category of categories) {
        flatCategories.push(category.title);
        if (category.children) {
          flatCategories.push(...flattenCategories(category.children).map((c) => `${category.title} ${c}`));
        }
      }
      return flatCategories;
    };
    return flattenCategories(groupementSupplementsCategories);
  }, [groupementSupplementsCategories]);


  const toogle = (supplement) => {
    if (!selectedSupplement.find(_sup => _sup.id === supplement.id))
      setSelectedSupplement([...selectedSupplement, { ...supplement, number: 1 }]);

  }

  const addNumber = (supplement) => {
    setSelectedSupplement(selectedSupplement.map(_sup => _sup.id === supplement.id ? { ...supplement, number: _sup.number + 1 } : _sup));
  }

  const removeNumber = (supplement) => {
    const newSelectedSupplement = selectedSupplement.map(_sup => _sup.id === supplement.id ? { ...supplement, number: _sup.number - 1 } : _sup);
    const filteredSelectedSupplement = newSelectedSupplement.filter(_sup => _sup.number > 0);
    setSelectedSupplement(filteredSelectedSupplement);
  }

  const groupedSupplements = useMemo(() => {
    const allSupplements = [...etabSupplements, ...groupementSupplements];
    let result = {}
    allSupplements.forEach(supplement => {
      const category = supplement.category?.replaceAll("|/|", " ") ?? "";
      if (category.length > 0) {
        if (!result[category]) result[category] = [];
        result[category].push({ id: supplement.id, name: supplement.name, price: supplement.price })
      }
    });

    result["Autres"] = allSupplements.filter(supplement => !supplement.category || supplement.category.length === 0)
      .map(supplement => ({ id: supplement.id, name: supplement.name, price: supplement.price }));
    return Object.fromEntries(Object.entries(result).sort(([key1], [key2]) => key1.localeCompare(key2)));
  }, [etabSupplements, groupementSupplements, flatCategories]);

  return (
    <>
      {supplements && supplements.map((_sup) => (
        <div key={_sup.id} className={styles.item}>
          {_sup.name}
          <div style={{ fontWeight: "bold" }}>x{_sup.number}</div>
        </div>
      ))}
      <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal} size="xl">
        <ModalHeader>Suppléments Restaurant</ModalHeader>
        <ModalBody className={styles.modalBody}>
          {
            Object.entries(groupedSupplements).map(([_categ, _list]) => {
              return _list.length > 0 ?
                (<div key={_categ}>
                  <div style={{ color: "purple", fontSize: 20 }}>{_categ}</div>
                  <div className={styles.supplementsContainer}>
                    {_list.sort((_a, _b) => _a.name.localeCompare(_b.name)).map(_sup => (
                      <div
                        className={styles.item}
                        key={_sup.id}
                        onClick={() => toogle(_sup)}
                      >
                        {_sup.name}
                        {selectedSupplement.find(s => s.id === _sup.id) && <div className={styles.quantitySelector}>
                          <FontAwesomeIcon
                            icon={faMinus}
                            color="white"
                            size="lg"
                            style={{ cursor: "pointer", backgroundColor: "#300438", padding: 5, borderRadius: "100%" }}
                            onClick={() => removeNumber(_sup)}
                          />
                          <div style={{ fontSize: 24 }}>{selectedSupplement.find(s => s.id === _sup.id).number}</div>
                          <FontAwesomeIcon
                            icon={faPlus}
                            color="white"
                            size="lg"
                            style={{ cursor: "pointer", backgroundColor: "#300438", padding: 5, borderRadius: "100%" }}
                            onClick={() => addNumber(_sup)}
                          />
                        </div>}
                      </div>
                    ))}
                  </div>
                </div>) :
                null
            }
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => onClose()}>Annuler</Button>
          <Button color="primary" onClick={() => { onSubmit(selectedSupplement); onClose(); }}>Valider</Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

export default SupplementRestaurant;