import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Checkbox,
    FormGroup,
    Select,
    Tag
} from "lib/components";

import { ChevronLeft, ChevronRight } from "react-feather";
import { useMenu } from "../../../router_context";
import styles from "../../../Basic/Assets/scss/modalResume.module.scss";
import moment from "moment";
import { sortCategories, isReservationEmpty } from "../../../helpers/operations";
import { findSubscriptionForDate } from "../../../../../../helpers/subscription";

import { ModalInformation } from "../../../router_components";

const fixe = ["assiette de fromage", "faisselle"];
const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";


const ModalResume = () => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenu();
    const [page, setPage] = useState(0);

    //aliases
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const dataDay = ctx?.data[ctx?.selectedDate];
    const reservation = useMemo(() => {
        let userResa = []
        
        if(ctx.reservationObject) {
            userResa = ctx.reservationObject
        } else {
            if(ctx?.data[ctx?.selectedDate] && ctx?.data[ctx?.selectedDate].reservation && ctx?.data[ctx?.selectedDate].reservation[ui.user.uid] && ctx?.data[ctx?.selectedDate].reservation[ui.user.uid][ctx.modalInfos.repas]) 
                userResa = ctx?.data[ctx?.selectedDate].reservation[ui.user.uid][ctx.modalInfos.repas];
            const guestResa = Object.entries(ctx.data[ctx?.selectedDate].reservation)
            .filter(([idResa, resa]) =>
            resa?.[ctx.modalInfos.repas]?.find(_resa => _resa.isGuest)
            && ctx.guests.find(_guest => _guest.uid === idResa)?.linkedTo === ui.user.uid
            )
            .map(([idResa, resa]) => ({uid: idResa, ...resa[ctx.modalInfos.repas][0]}));

            userResa = [...userResa, ...guestResa];
        }
        

        return userResa;
    }, [ctx]);
    const currentUser = ui.user;
    const subscriptionObject = findSubscriptionForDate(currentUser?.subscriptions, ctx?.selectedDate);
    const subscription = subscriptionObject?.subscription;

    const confirmation = () => {

        if (ctx.modalValidation == "save" && ctx.reservationObject) {
            ctx.updateMenu(ctx.reservationObject);
        }

        if (ctx.modalValidation === "delete") {
            ctx.updateMenu("delete");
        }

        dispatch({ type: "setProperty", property: "modalResumeOpen", value: false });
    };

    const cancel = () => {
        dispatch({ type: "setProperty", property: "modalResumeOpen", value: false });
        dispatch({ type: "setProperty", property: "reservationObject", value: null });
    };

    return (
        <Modal isOpen={ctx.modalResumeOpen} toggle={cancel} size="xl" className={styles.modal}>
            <ModalHeader className={styles.modalHeader}>
                <div className={styles.modalHeaderComponent}>
                    {ctx.modalValidation === "delete" || (reservation && reservation[0] &&  reservation[0].homeDelivery === false) || (reservation && reservation[0] && reservation[0].createdLocally === true)
                    ? <div className={styles.headerInfo}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div>
                    :  <div className={styles.headerInfo}></div>
                    }
                    <div className={styles.modalTitle}>
                        Réservation pour le {ctx.modalInfos.repas} du <span className={styles.headerDate}>{moment(ctx?.selectedDate).format("dddd DD MMMM")}</span>
                    </div>

                    <div className={styles.headerContent}>
                        {page === 0 && templateSubscription ?
                            subscription ?
                                subscription === "1/2p Duo" ?
                                    <div>
                                        <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                                        <Tag className={styles.tag} style={{ backgroundColor: colors[1] }}><div>DUO</div></Tag>
                                    </div>
                                    :
                                    subscription === "1/2p Trio" ?
                                        <div>
                                            <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                                            <Tag className={styles.tag} style={{ backgroundColor: colors[2] }}><div>TRIO</div></Tag>
                                        </div>
                                        : null
                                :
                                <Tag className={styles.tag} style={{ backgroundColor: colorUnite }}><div>Unité</div></Tag>

                            : null}
                    </div>

                </div>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
                {reservation.length > 0 ?
                    !isReservationEmpty(template, ctx.modalInfos.repas, reservation) && !reservation[0].createdLocally && reservation[0].homeDelivery === true ?
                        <div className={styles.containerConfirmation}>
                            {ctx.modalValidation !== "delete" && reservation[0].homeDelivery === true ?
                                <>
                                    <div className={styles.headerResa}>
                                        <div className={styles.resaList}>
                                            <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
                                            {reservation.length > 1 ?
                                                reservation.map((_r, indx) => (
                                                    indx > 0 ?
                                                        <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                                                            <div>
                                                                {ctx.guests.find(g => g.uid === _r?.uid)?.name} {ctx.guests.find(g => g.uid === _r?.uid)?.surname}
                                                            </div>
                                                        </div>
                                                        : null
                                                ))
                                                : null}
                                        </div>
                                    </div>
                                    <div className={styles.resas}>
                                        {[...sortCategories(template, ctx.modalInfos.repas, template[ctx.modalInfos.repas].categories), "supplément"].filter((a) => reservation[page][a]).map((categ, indx) => (
                                            dataDay[ctx.modalInfos.repas] && reservation[page][categ] && (categ === "supplément" || dataDay[ctx.modalInfos.repas][categ] && (dataDay[ctx.modalInfos.repas][categ][reservation[page][categ]])) ?
                                                <div key={categ}>
                                                    <div className={styles.dishTitle}>
                                                        {categ}
                                                    </div>
                                                    <div className={styles.dish}>
                                                        {fixe.includes(reservation[page][categ]) ? reservation[page][categ] : dataDay[ctx.modalInfos.repas][categ === "supplément" ? "dessert" : categ][reservation[page][categ]].name}
                                                    </div>
                                                </div>
                                                : null
                                        )
                                        )}
                                    </div>
                                    <ModalInformation reservation={reservation} page={page} />
                                </>

                                :
                                <div className={styles.infos}>
                                    {ctx.modalValidation !== "delete" ? `Votre réservation de ${reservation.length} repas ${reservation[0].homeDelivery === true ? "avec portage " : ""}.` : `Vous allez supprimer votre réservation pour le ${ctx.modalInfos.repas} du ${moment(ctx.selectedDate).format("dddd DD MMMM")}.`}
                                </div>}
                        </div>
                        :
                        <div className={styles.containerConfirmation}>
                            <div className={styles.infos}>
                                {ctx.modalValidation !== "delete" ? `Votre réservation ${reservation[0].homeDelivery === true ? "avec portage " : ""} pour le ${ctx.modalInfos.repas} du ${moment(ctx.selectedDate).format("dddd DD MMMM")} est bien prise en compte.` : `Vous allez supprimer votre réservation pour le ${ctx.modalInfos.repas} du ${moment(ctx.selectedDate).format("dddd DD MMMM")}.`}
                            </div>
                        </div>
                    : null}
            </ModalBody>
            <ModalFooter>
                <div className={styles.footer}>
                    <div className={styles.leftInfos}>
                        <div>
                            Total : {reservation ? reservation.length : 0} repas {reservation && reservation[0]?.homeDelivery ? "avec portage" : null}
                        </div>
                    </div>
                    <div className={styles.rightButtons}>
                        <Button className={styles.button} color="secondary" onClick={cancel}>{ctx.reservationObject ? "Annuler" : "Retour"}</Button>
                        <Button className={styles.button} color="secondary" onClick={() => {
                            dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
                            dispatch({ type: "setProperty", property: "modalResumeOpen", value: false });
                            dispatch({ type: "setProperty", property: "modalValidation", value: "modification" });
                        }}>Modifier</Button>
                        <Button className={styles.button} color="primary" onClick={confirmation}>Confirmer</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

const SuspenseHOC = (props) => {
    const [ctx] = useMenu();

    if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
    return <ModalResume {...props} />;
};


export default SuspenseHOC;
