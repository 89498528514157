import React, { useMemo, useState, useEffect, useCallback } from "react"
import { Input, Select, Modal, ModalBody, ModalFooter, ModalHeader, Button, DatePicker } from "@/lib/components"
import firebase from "firebase";
import useUI from "@/hooks/ui.hook";
import { filterRepas } from "@/pages/Major/Menu/helpers/operations";
import { create } from 'zustand'

const firestore = firebase.firestore;

const GuestSelectorModal = (props) => {
  const {
    isOpen,
    onSubmit = (data) => {console.log("DEFAULT SUBMIT",data)},
    onClose = () => {},
    linkedTo = null,
    guestTypeFilter = [],
    date = null,
    service = null,
  } = props

  const [ui] = useUI();
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedService, setSelectedService] = useState(service);
  const [search, setSearch] = useState('');
  const [guests, setGuests] = useState([]);
  const [users, setUsers] = useState([]);
  const [showAddingForm, setShowAddingForm] = useState(false);

  const [newGuestName, setNewGuestName] = useState('');
  const [newGuestSurname, setNewGuestSurname] = useState("");

  const [newGuestType, setNewGuestType] = useState('');
  const [newGuestLinkedTo, setNewGuestLinkedTo] = useState('');
  const [errors, setErrors] = useState([]);

  const guestTypeOptions = [
    { value: 'resident', label: 'Invité de résident' },
    { value: 'establishmentGuest', label: 'Invité de l\'établissement' },
    { value: 'guest', label: 'Client externe' },
  ].filter((_guestType) => !guestTypeFilter.includes(_guestType.value));


  useEffect(() => {
    const unsubscribe = firestore()
    .collection("users")
    .where("establishment", "==", ui.user.establishment)
    .where("role", "in", ["guest"])
    .onSnapshot(s => {
      let _guests = [];
      s.forEach(doc => {
        _guests.push({ uid: doc.id, ...doc.data() });
      });
      setGuests(_guests);
    });

    const unsubscribe2 = firestore()
    .collection("users")
    .where("establishment", "==", ui.user.establishment)
    .where("role", "in", ["senior","seniorTotem"])
    .onSnapshot(s => {
      let _users = [];
      s.forEach(doc => {
        if(!doc.data().isDeleted)
          _users.push({ uid: doc.id, ...doc.data() });
      });
      setUsers(_users);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [])

  useEffect(() => {
    if (!showAddingForm) {
      setNewGuestName('');
      setNewGuestSurname('');
      setNewGuestType('');
      setNewGuestLinkedTo('');
      setErrors([]);
    }
    if(!isOpen) {
      setShowAddingForm(false);
      setSearch('');
      setSelectedDate(null);
    }
  }, [showAddingForm, isOpen])

  // Sert à selectionner automatiquement le type d'invité si la modale a les paramètre de sélection pour un résident
  useEffect(() => {
    guestTypeOptions.every(_guestType => _guestType.value === 'resident') && setNewGuestType('resident');
    if(date) setSelectedDate(date);
    if(service)setSelectedService(service);
  }, [guestTypeOptions, date, service])

  const guestList = useMemo(() => {
    return guests
      // Si il y a un props linkedTo on garde seulement les invitites liés
      .filter(_guest => linkedTo ? _guest.linkedTo === linkedTo : true)
      // On retire les types de personnes qui ne doivent pas apparaitre
      .filter((_guest) => !guestTypeFilter.includes(_guest.type))
      // On match la recherche
      .filter((_guest) => `${_guest?.name?.toLowerCase()} ${_guest?.surname?.toLowerCase()}`.includes(search.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [search, linkedTo, guests]);

  const findUser = useCallback((uid) => {
    const res = users.find(user => user.uid === uid);
    if (res) {return {value: res.uid, label: `${res.room ?? ""} ${res.name ?? ""} ${res.surname ?? ""}`}};
    return null;
  }, [users, newGuestLinkedTo]);

  const addGuest = async () => {
    const _errors = [];
    const data = {
      name: newGuestName[0].toUpperCase() + newGuestName.slice(1).toLowerCase(),
      surname: newGuestSurname.toUpperCase(),
      type: newGuestType,
      ...(newGuestLinkedTo && newGuestType === "resident" && { linkedTo: newGuestLinkedTo }),
      ...(linkedTo && newGuestType === "resident" && { linkedTo: linkedTo }),
      role: "guest",
      establishment: ui.user.establishment,
      createdAt: new Date(),
    }

    // Validation de l'invité, validation des champs, doublons
    if(data.name.length < 3) _errors.push({field: "name", message: "Le prénom de l'invité doit contenir au moins 3 caractères"});
    if(data.surname.length < 3) _errors.push({field: "surname", message: "Le nom de l'invité doit contenir au moins 3 caractères"});

    if(guests.find((_guest) => `${_guest.name.toLowerCase()} ${_guest.surname.toLowerCase()}` === `${data.name.toLowerCase()} ${data.surname.toLowerCase()}`)) _errors.push({field: "surname", message: "Ce prénom et nom d'invité existe déjà"});
    setErrors(_errors);
    if(_errors.length > 0) return;

    try {
      const result = await firestore()
      .collection("users")
      .add(data);
      onSubmit({...data, uid: result.id}, selectedDate, selectedService); onClose()
    } catch (error) {
      console.log("error", error);
    }

    onClose();
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader>
        Séléction d'invité {linkedTo && `de ${users.find(_user => _user.uid === linkedTo)?.name.toUpperCase() ?? ""} ${users.find(_user => _user.uid === linkedTo)?.surname ?? ""}`}
      </ModalHeader>
      <ModalBody style={{ display: "flex", flexDirection: "column", gap: "20px"}}>
        {(!selectedDate || !selectedService)  &&
          <>
            
            <DatePicker value={selectedDate} onChange={setSelectedDate} placeholder="Date du repas" label="Sélectionner une date" />
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
              { filterRepas(ui.establishment.template, Object.keys(ui.establishment.template)).sort((a,b) => ui.establishment.template[a].heure - ui.establishment.template[b].heure).map(_repas => 
                // console.log("repas",_repas),
                <Button key={_repas} onClick={() => setSelectedService(_repas)} style={{ backgroundColor: selectedService === _repas ? "#821097" : "#51075e", color: "white", fontWeight: "bold"}}>{_repas}</Button>
              )}
            </div>
          </>

        }

        {selectedDate && selectedService && !showAddingForm && <div id="search" style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
          <Input value={search} onChange={e => setSearch(e.target.value)} placeholder="Rechercher un invité"/>
          <div id="result" style={{ display: "flex", flexDirection: "column", gap: "10px", maxHeight: "300px", overflowY: "scroll"}}>
            {guestList.map(guest => 
              <div key={guest.uid} style={{ cursor: "pointer", border: "1px solid #51075e", padding: "10px", borderRadius: "10px", color: "#51075e", fontWeight: "bold", textAlign: "center"}} onClick={() => {onSubmit(guest, selectedDate, selectedService); onClose()}}>{guest.surname} {guest.name}</div>
            )}
            {guestList.length === 0 && 
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <div>Aucun invité trouvé</div>
                <Button onClick={() => setShowAddingForm(true)}>Créer un invité</Button>
              </div>
            }
          </div>
        </div>}
        




        {selectedDate && selectedService && showAddingForm && <div id="addGuestContainer" style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
          <h5>Ajouter un nouvel invité</h5>
          <div>
            <Input value={newGuestSurname} onChange={e => setNewGuestSurname(e.target.value)} placeholder="Nom"/>
            {errors.find(error => error.field === "surname") && 
              errors.filter(error => error.field === "surname").map(error => <div style={{ color: "red"}}>{error.message}</div>)
            }
          </div>
          <div>
            <Input value={newGuestName} onChange={e => setNewGuestName(e.target.value)} placeholder="Prénom"/>
            {errors.find(error => error.field === "name") && 
              errors.filter(error => error.field === "name").map(error => <div style={{ color: "red"}}>{error.message}</div>)
            }
          </div>
          {
            !guestTypeOptions.every(type => type.value === "resident") && <Select 
              value={guestTypeOptions.find(type => type.value === newGuestType)} 
              onChange={e => setNewGuestType(e.value)} 
              options={guestTypeOptions.map(type => {return {value: type.value, label: type.label}})}
              placeholder="Type d'invité"
            />
          }
          {newGuestType === "resident" && !linkedTo && 
            <Select 
              value={findUser(newGuestLinkedTo) ?? null}
              placeholder="Rechercher un résident"
              onChange={e => setNewGuestLinkedTo(e.value)} 
              options={users
                .sort((u1, u2) => u1.name.localeCompare(u2.name))
                .map(user => {return {value: user.uid, label: `${user.room ?? ""} ${user.name ?? ""} ${user.surname ?? ""}`}})
              }
            />
          }
        </div>}

      </ModalBody>
      <ModalFooter>
        {selectedDate && selectedService && !showAddingForm && <Button onClick={() => setShowAddingForm(true)}>Créer un invité</Button>}
        {showAddingForm && <Button color="primary" onClick={() => {addGuest()}} disabled={!newGuestName || !newGuestSurname || !newGuestType || (newGuestType === "resident" && !newGuestLinkedTo && !linkedTo)}>Créer un invité</Button> }
        <Button color="secondary" onClick={showAddingForm ? () => setShowAddingForm(false) : (selectedDate || selectedService) && !date && !service ? () => {setSelectedService(null); setSelectedDate(null);} : onClose}>Retour</Button>
      </ModalFooter>
    </Modal>
  )
}


const guestSelectorModalStore = create((set) => ({
  
  isOpen: false,
  setIsOpen: (open) => set({ isOpen: open }),

  props: {},
  setProps: (props) => set({ props }),

}));

export default GuestSelectorModal;
export {guestSelectorModalStore};