import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormGroup,
  Tag
} from "lib/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserTimes, } from "@fortawesome/free-solid-svg-icons";
import { CategorySelector, GuestSelectorModal } from "../../../router_components";
import { useMenu } from "../../../router_context";
import deepClone from "helpers/deepClone";
import deepEqual from "deep-equal";
import { getDefaultRepas, isReservationEmpty } from "../../../helpers/operations";
import { findSubscriptionForDate } from "../../../../../../helpers/subscription";
import {guestSelectorModalStore} from "../../../Basic/Components/GuestSelectorModal";

import { PlusCircle, Trash } from "react-feather";
import styles from "../../../Basic/Assets/scss/modalReservation.module.scss";

import moment from "moment";

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalReservation = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenu();

  const [reservation, setReservation] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [page, setPage] = useState(0);

  const isGuestSelectorModalOpen = guestSelectorModalStore((state) => state.isOpen);
  const setIsGuestSelectorModalOpen = guestSelectorModalStore((state) => state.setIsOpen);
  const guestSelectorModalProps = guestSelectorModalStore((state) => state.props);
  const setGuestSelectorModalProps = guestSelectorModalStore((state) => state.setProps);

  //aliases
  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription;
  const isEmployee = ui.user.role === "employee";
  const currentUser = ui.user;
  const dataDay = ctx?.data[ctx?.selectedDate];
  const subscriptionObject = findSubscriptionForDate(currentUser?.subscriptions, ctx?.selectedDate);
  const subscription = subscriptionObject?.subscription;

  useEffect(() => {
    if (!ctx.reservationObject) {
      try {
        setPage(0);
        const guestResa = Object.entries(ctx.data[ctx?.selectedDate].reservation)
        .filter(([idResa, resa]) =>
          resa?.[ctx.modalInfos.repas]?.find(_resa => _resa.isGuest)
          && ctx.guests.find(_guest => _guest.uid === idResa)?.linkedTo === ui.user.uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[ctx.modalInfos.repas][0]}));
        
        const _resa = [...deepClone(ctx.data[ctx.selectedDate].reservation[ui?.user?.uid][ctx.modalInfos.repas]), ...guestResa];
        
        if (_resa) {
          setReservation(_resa);

          if (_resa[0] && _resa[0].status !== "canceled") {
            setEmpty(false);
          } else {
            setEmpty(true);
          }
        } else {
          setReservation([{}]);
          setEmpty(true);
        }

      } catch (error) {
        setReservation([{}]);
        setEmpty(true);
        setPage(0);
      }
    }
  }, [ctx.selectedDate, ui?.user?.uid, ctx.modalInfos.repas]);

  const _save = () => {
    // ctx.updateMenu(reservation);
    // dispatch({ type: "setProperty", property : "modalResaOpen", value:false});
    // dispatch({ type: "setProperty", property : "modalInfos", value:null});
    dispatch({ type: "setProperty", property: "reservationObject", value: reservation });
    dispatch({ type: "setProperty", property: "modalValidation", value: "save" });
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
  };

  const _delete = () => {
    // if(confirm("Voulez vous vraiment supprimer votre réservation ?")){
    // ctx.updateMenu("delete");
    // dispatch({ type: "setProperty", property : "modalResaOpen", value:false});
    // dispatch({ type: "setProperty", property : "modalInfos", value:null});
    dispatch({ type: "setProperty", property: "modalValidation", value: "delete" });
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
    // }
  };


  const updateHomeDelivery = () => {
    let currentValue = reservation[0].homeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.homeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updateSelection = (category, choice) => {
    let currentResa = deepClone(reservation);

    currentResa[page][category] = choice;

    setReservation(currentResa);
  };

  const addResa = (uid) => {
    let currentResa = deepClone(reservation);
    if(reservation.find(resa => resa.uid === uid)) return;
    currentResa.push({
      isGuest: true,
      uid: uid,
    });

    setReservation(currentResa);
    setPage(currentResa.length - 1);
  };

  const removeResa = () => {
    let indxToRemove = page > 0 ? page : null;

    if (indxToRemove) {
      let currentResa = deepClone(reservation);

      currentResa.splice(indxToRemove, 1);

      setReservation(currentResa);
      setPage(page - 1);
    }
  };

  const retour = () => {

    if (ctx.modalValidation === "modification") {
      dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
      dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
    } else {
      dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    }
  };

  const hasChanged = useMemo(() => {
    let _initial = [];

    // On compare la resa actuelle à la resa initiale :
    if(ctx.data[ctx.selectedDate] && ctx.data[ctx.selectedDate].reservation && ctx.data[ctx.selectedDate].reservation[ui.user.uid]) {
      const userResa = ctx.data[ctx.selectedDate].reservation[ui.user.uid][ctx.modalInfos.repas];
      const guestResa = Object.entries(ctx.data[ctx?.selectedDate].reservation)
        .filter(([idResa, resa]) =>
          resa?.[ctx.modalInfos.repas]?.find(_resa => _resa.isGuest)
          && ctx.guests.find(_guest => _guest.uid === idResa)?.linkedTo === ui.user.uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[ctx.modalInfos.repas][0]}));

      _initial = [...userResa ?? [], ...guestResa];
    }

    if (deepEqual(_initial, reservation)) {
      return false;
    } else {
      return true;
    }
  }, [reservation, ctx]);

  return (
    <Modal isOpen={ctx.modalResaOpen} toggle={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })} className={styles.modal}>
      <ModalHeader className={styles.modalHeader}>
        <div className={styles.modalHeaderComponent}>
          {/* <div className={styles.headerInfo}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div> */}
          <div className={styles.headerInfo}></div>

          <div className={styles.modalTitle}>
            Réservation pour le {ctx.modalInfos.repas} du <span className={styles.headerDate}>{moment(ctx?.selectedDate).format("dddd DD MMMM")}</span>
          </div>

          <div className={styles.headerContent}>
            {page === 0 && templateSubscription ?
              <Tag className={styles.tag} style={{ backgroundColor: subscription ? colors[Object.keys(templateSubscription).indexOf(subscription) % 5] : colorUnite }}><div>{subscription ?? "Unité"}</div></Tag>
              : null}
          </div>

        </div>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <div className={styles.modalContainer}>
          <div className={styles.headerResa}>
            <div className={styles.resaList}>
              <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
              {reservation.length > 1 ?
                reservation.map((_r, indx) => (
                  indx > 0 ?
                    <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                      <div>
                        {ctx.guests.find(g => g.uid === _r?.uid)?.name} {ctx.guests.find(g => g.uid === _r?.uid)?.surname}
                      </div>
                    </div>
                    : null
                ))
                : null}
            </div>
            <div className={styles.resaButton}>
              {!isEmployee ?
                <>
                  {page > 0 ? <div color="danger" onClick={removeResa} style={{ color: "red" }}> <FontAwesomeIcon icon={faUserTimes} color="red" /> Supprimer cette invitation</div> : null}
                  <div onClick={() => {
                    setGuestSelectorModalProps({
                      ...guestSelectorModalProps, 
                      linkedTo: ["seniorTotem","senior"].includes(currentUser.role) ? currentUser.uid : null, 
                      date: ctx?.selectedDate, 
                      service: ctx.modalInfos.repas, 
                      guestTypeFilter: ["guest", "establishmentGuest"],
                      onSubmit: (guest, selectedDate, selectedService) => {addResa(guest.uid); console.log("coucou", guest, selectedDate, selectedService)}
                    });
                    setIsGuestSelectorModalOpen(true);
                  }}> <FontAwesomeIcon icon={faUserPlus} color="#300438" /> Inviter une personne</div>
                </>
                : null}
            </div>
          </div>
          {/* <ModalInformation reservation={reservation} page={page} /> */}
          <div className={styles.content}>

            {reservation ?
              <CategorySelector page={page} dataReservation={reservation[page]} updateSelection={(category, choice) => updateSelection(category, choice)} />
              : null}
          </div>

        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <div className={styles.nbresas}>
              Total : {reservation ? reservation.length : 0} repas
            </div>
            {!isEmployee ? <div>
              <Checkbox className={styles.checkbox} label="portage" checked={reservation ? reservation[0]?.homeDelivery : false} onChange={updateHomeDelivery} />
            </div> : <div></div>}
          </div>

          <div className={styles.footerInfos}>
            {isReservationEmpty(template, ctx.modalInfos.repas, reservation) ?
              <div className={styles.notValid}>
                Touchez les plats que vous voulez réserver.
              </div> : null}
          </div>

          <div className={styles.footerButtons}>
            {!empty ?
              <Button color="danger" onClick={_delete}>Supprimer</Button>
              : null}
            <Button color="secondary" onClick={retour}>Retour</Button>
            {dataDay ? <Button color="primary" disabled={!hasChanged} onClick={_save}>Valider</Button> : null}
          </div>

        </div>
      </ModalFooter>
      <GuestSelectorModal 
        {...guestSelectorModalProps}
        isOpen={isGuestSelectorModalOpen} 
        onClose={() => setIsGuestSelectorModalOpen(false)}
      />
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenu();

  if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalReservation {...props} />;
};


export default SuspenseHOC;
