import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Button,
    Checkbox,
    FormGroup,
    Select
} from "lib/components";
import moment from "moment";
import { useMenu } from "../../router_context";
import { DishCard, SupplementRestaurant } from "../../router_components";
import PropTypes from "prop-types";
import { sortDishesWithMenu, isCategoryEmpty } from "pages/Major/Menu/helpers/operations";
import { findSubscriptionForDate } from "../../../../../helpers/subscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import styles from "..//Assets/scss/categorySelector.module.scss";
import useFeature from "../../../../../hooks/useFeature";

const CategorySelector = (props) => {
    const { page, dataReservation, updateSelection } = props;

    const [ui] = useUI();
    const [ctx, dispatch] = useMenu();
    const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
    
    const currentUser = ui.user;
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscriptionObject = findSubscriptionForDate(currentUser?.subscriptions, ctx?.selectedDate);
    const subscription = subscriptionObject?.subscription;
    const dataDay = ctx?.data[ctx?.selectedDate];
    const hasRestauranFeature = useFeature("restaurant");


    const handleClick = (cat, dishId) => {
        if (dataReservation[cat] === dishId)
            updateSelection(cat, null);
        else
            updateSelection(cat, dishId);
    };

    const isSupplement = useCallback((cat) => {
        if (page === 0 && template && templateSubscription && subscription && templateSubscription[subscription].repas[ctx.modalInfos.repas]) {
            // s'il y a un abonnement pour ce repas.
            if (!templateSubscription[subscription].repas[ctx.modalInfos.repas].includes(cat)) return true;
        }
        return false;
    }, [page, dataReservation, ctx]);

    return (
        <div className={styles.container}>


            {template[ctx.modalInfos.repas].categories.map((cat, indx) => (
                dataDay && dataDay[ctx.modalInfos.repas] && dataDay[ctx.modalInfos.repas][cat] && !isCategoryEmpty(dataDay[ctx.modalInfos.repas][cat]) ?

                    <div key={indx} className={styles.categComponent}>
                        <div className={styles.categTitle}>
                            {cat} {isSupplement(cat) ? <span className={styles.supplement}>(en supplément)</span> : null} :
                        </div>
                        <div className={styles.categChoices}>
                            {
                                sortDishesWithMenu(template, ctx.modalInfos.repas, Object.entries(dataDay[ctx.modalInfos.repas][cat])).map(([dishId, dish], indx2) => (
                                    <div key={indx2}>
                                        <DishCard selected={dataReservation[cat] === dishId} dishId={dishId} dishInfo={dish} handleClick={(dishId) => handleClick(cat, dishId)} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : null
            ))}
            { hasRestauranFeature &&<div className={styles.categComponent}>
                <div className={styles.categTitle} style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    Suppléments Restaurant
                    <div style={{cursor: "pointer", color: "#5F0D6D"}} onClick={() => setIsModalSupplementOpen(true)}>
                        <FontAwesomeIcon
                            icon={faEdit}
                            size="1x"
                            color="#5F0D6D"
                        />
                        Éditer
                    </div>
                </div>
                <div className={styles.categChoices}>
                    {
                        <SupplementRestaurant 
                            isOpen={isModalSupplementOpen}
                            onClose={() => setIsModalSupplementOpen(false)}
                            onSubmit={(supplement) => {
                                updateSelection("suppléments restaurant", supplement);
                            }} 
                            supplements={dataReservation["suppléments restaurant"] ?? []}
                        />
                    }
                </div>
            </div>}


        </div>
    );
};


CategorySelector.propTypes = {
    dataReservation: PropTypes.object,
    updateSelection: PropTypes.func,
    page: PropTypes.number,
};


export default CategorySelector;
