import React, {useMemo} from "react";
import PropTypes from "prop-types";

import styles from "assets/scss/pages/Menu/repasContainer.module.scss";
import { Button } from "lib/components";
import useUI from "hooks/ui.hook";
import useFeature from "hooks/useFeature";
import { sortMenus, hasBooked, isRepasEmpty, isMenuEmpty } from "../../../helpers/operations";
import { HomeOutline } from "react-ionicons";

import { useMenu } from "../../../router_context";
import { MenuCard } from "../../../router_components";
import { DateWall } from "../../../router_functions";
const Repas = ({ repas }) => {
    const [ctx, dispatch] = useMenu();
    const [ui] = useUI();
    const dateWall = DateWall(ctx, repas);

    const isReservation = useFeature("reservationMenu");
    const template = ui.establishment.template;
    const totalRepas = useMemo(() => {
        let totalUser = null;

        if(ctx?.data[ctx?.selectedDate]?.reservation 
            && ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid] 
            && ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas]
        ) {
                totalUser = ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas].length > 0 ?
                ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas].length : null
        }

        const guestResa = Object.entries(ctx.data[ctx?.selectedDate].reservation)
        .filter(([idResa, resa]) =>
          resa?.[repas]?.find(_resa => _resa.isGuest)
          && ctx.guests.find(_guest => _guest.uid === idResa)?.linkedTo === ui.user.uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[repas][0]}));

        if(totalUser === null && guestResa.length === 0) return null;
        return `${(totalUser ?? 0) + guestResa.length} repas`;
    }, [ctx])

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {repas}
            </div>
            {dateWall && isReservation && !ctx.disabled && !isRepasEmpty(ctx.data[ctx.selectedDate][repas]) ?
                <div className={styles.reservationHeader}>
                    <div>
                        {ctx?.data[ctx?.selectedDate]?.reservation &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas][0] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas][0].homeDelivery === true ?
                            <HomeOutline width={"30px"} height={"30px"} />
                            : null}

                    </div>
                    <div className={styles.buttonContainer}>
                        <Button color="primary" onClick={() => {
                            if (hasBooked(ctx.data[ctx.selectedDate], ui.user.uid, repas)) {
                                dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
                                } else {
                                dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
                            }
                            dispatch({ type: "setProperty", property: "modalInfos", value: { repas: repas } });
                        }}>{hasBooked(ctx.data[ctx.selectedDate], ui.user.uid, repas) ? "Modifier" : "Réserver"}</Button>

                    </div>
                    <div>{totalRepas}</div>
                </div>

                : null}
            {isRepasEmpty(ctx.data[ctx.selectedDate][repas]) ?
                <div className={styles.noMenu}>
                    Aucun menu défini pour le {repas} de ce jour
                </div>
                :
                <div className={styles.cardContainer}>
                    {sortMenus(template, repas, Object.keys(template[repas].menus)).slice(0,1).map((menu, index) => (
                        !isMenuEmpty(ctx.data[ctx.selectedDate][repas], menu) ?
                            <div key={index}>
                                <MenuCard repas={repas} menu={menu} />
                            </div>
                            : null
                    ))} 
                </div>
            }



        </div>
    );
};

Repas.propTypes = {
    repas: PropTypes.string.isRequired,
};

export default Repas;