import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "services/firebase";

import moment from "moment";
import useUI from "hooks/ui.hook";
//import deepClone from "helpers/deepClone";
import { toast } from "react-toastify";
import useFeature from "hooks/useFeature";
import deepClone from "../../../../../helpers/deepClone";
//import deepClone from "../../../../helpers/deepClone";
import { sortCategories, getDefaultDay, isRepasEmpty, isDayEmpty } from "../../helpers/operations";
import { sendNotifToMajor } from "services/notifications";
import { findSubscriptionForDate } from "../../../../../helpers/subscription";

const firestore = firebase.firestore;


const Context = createContext();

const Default = {
  dataBase: null,
  data: null,
  selectedDate: null,
  selectedRepas: null,
  disabled: false,
  delay: 0,
  daysToDisplay: [1, 2, 3, 4, 5, 6, 7],
  loadingMenu: true,
  modification: false,
  frontPage: false,
  modalUser: "",
  menuType: "menu",
  returnToday: 0,
  banner: "",
  updateMenu: null,
  modalResaOpen: false,
  modalResumeOpen: false,
  modalValidation: null,
  reservationObject: null,
  modalInfos: null,
  guests: [],
};

function Reducer(state, action) {
  switch (action.type) {
    case "setProperty": return ({ ...state, [action.property]: action.value });
    default: return { ...state };
  }
}

const Provider = ({ children }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);

  const template = ui.establishment.template;
  const templateSubscription = ui?.establishment?.templateSubscription;

  //init
  useEffect(() => {
    (async () => {

      firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .onSnapshot(res => {
          let data = res.data();
          if (data.delay) dispatch({ type: "setProperty", property: "delay", value: data.delay });

          if (data.daysToDisplay && data.daysToDisplay.length > 0)
            dispatch({ type: "setProperty", property: "daysToDisplay", value: data.daysToDisplay });

          if (data.banner != undefined)
            dispatch({ type: "setProperty", property: "banner", value: data.banner });
        });


      try {
        firestore()
          .collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("menu")
          .collection("menu")
          .where("published", "==", true)
          .onSnapshot(s => {
            let _dataMenus = {};
            s.forEach(doc => {
              _dataMenus[doc.id] = doc.data();
            });
            dispatch({ type: "setProperty", property: "dataBase", value: _dataMenus });
          });
      } catch (e) {
        toast.error("Une erreur est survenue.", e.message);
      }

      
      firestore()
        .collection("users")
        .where("establishment", "==", ui.user.establishment)
        .where("role", "in", ["guest"])
        .onSnapshot(s => {
          let _guests = [];
          s.forEach(doc => {
            _guests.push({uid: doc.id, ...doc.data()});
          });
          dispatch({ type: "setProperty", property: "guests", value: _guests });
        });
    



      //if(ui.establishment.menuType && ui.establishment.menuType === "menu") dispatch({ type: "setProperty", property : "menuType", value: "menu" });

    })()
  }, []);

  useEffect(() => {
    if (ctx.dataBase) {
      const newData = deepClone(ctx.dataBase);
      // Gestion des abonnements :
      if (templateSubscription) {
        // on rajoute toutes les réservations nécessaires.

        Object.entries(newData).forEach(([_day, _menu]) => {
          const _defaultDay = getDefaultDay(template, _menu);
          if (!_menu.reservation) _menu.reservation = {};
          const _reservations = _menu.reservation;

          // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
          const _user = ui.user;
          const _uid = _user.uid;
          const subscription = findSubscriptionForDate(_user?.subscriptions, _day);
          const _subscription = subscription?.subscription;
          const _subscriptionHomeDelivery = subscription?.homeDelivery ?? {};
          const _subscriptionDays = subscription?.days ?? [1, 2, 3, 4, 5, 6, 7];

          // si l'utilisateur a des abonnements.
          if (_subscription) {
            // on vérifie s'il est abonné pour ce jour.
            if (_subscriptionDays.includes(moment(_day).isoWeekday())) {


              // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
              // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


              if (templateSubscription[_subscription]) {
                Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                  // si il n'y a pas de resa pour ce repas on la rajoute.
                  if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                    if (!_reservations[_uid]) _reservations[_uid] = {};

                    const _newResa = {};

                    _repasInfos.forEach((_category) => {
                      _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                    });

                    _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: typeof (_subscriptionHomeDelivery) === "object" ? _subscriptionHomeDelivery[_repas] ?? false : _subscriptionHomeDelivery ?? false, createdLocally: true }];
                  } else {
                    // dans le cas où la réservation est annulée, on enlève la résa
                    if (_reservations[_uid][_repas] && _reservations[_uid][_repas][0].status === "canceled") {
                      delete _reservations[_uid][_repas];
                    }
                  }

                });
              }

            }

          }

        });
      }

      dispatch({ type: "setProperty", property: "data", value: newData });

      if (ctx.loadingMenu)
        dispatch({ type: "setProperty", property: "loadingMenu", value: false });
    }
  }, [ctx.dataBase]);


  useEffect(() => {
    if (ctx.data) {
      dispatch({ type: "setProperty", property: "modification", value: false });
    }
  }, [ctx.selectedDate, ctx.selectedRepas]);


  useEffect(() => {
    if (ctx.data && ctx.selectedDate) {
      if (ctx.data[ctx.selectedDate]) {

        if (ctx.selectedRepas === null || isRepasEmpty(ctx.data[ctx.selectedDate][ctx.selectedRepas])) {
          if (!isDayEmpty(template, ctx.data[ctx.selectedDate]) && ui.establishment.template) {
            let _repas = Object.keys(ui.establishment.template);

            _repas = _repas.sort(
              (a, b) =>
                ui.establishment.template[a].heure -
                ui.establishment.template[b].heure,
            );

            let chosen = false;
            _repas.forEach((repasId) => {
              if (!chosen) {
                if (!isRepasEmpty(ctx.data[ctx.selectedDate][repasId])) {
                  chosen = true;
                  dispatch({ type: "setProperty", property: "selectedRepas", value: repasId });
                }
              }
            });
          }
        }
      }


    }
  }, [ctx.selectedDate]);

  useEffect(() => {
    if (ctx.modalResumeOpen === false && ctx.modalResaOpen === false) {
      dispatch({ type: "setProperty", property: "modalInfos", value: null });
      dispatch({ type: "setProperty", property: "modalValidation", value: null });
      dispatch({ type: "setProperty", property: "reservationObject", value: null });
    }
  }, [ctx.modalResumeOpen, ctx.modalResaOpen]);



  const updateMenu = async (_resa, date, uid, repas) => {
    // date = "2022-07-15" options = {"midi" : 0, "soir" : 2} action = ["update", "remove"]
    //let docId = moment(date).format("YYYY-MM-DD");
    let map = {};

    let _date = date ?? ctx.selectedDate ?? null;
    let _uid = uid ?? ui.user.uid ?? null;
    let _repas = repas ?? ctx.modalInfos.repas ?? null;

    //console.log(_date, _uid, _repas);
    //let isMapEmpty = true;

    const guestResa = Object.entries(ctx.data[_date].reservation)
        .filter(([idResa, resa]) =>
          resa?.[_repas]?.find(_resa => _resa.isGuest)
          && ctx?.guests.find(_guest => _guest.uid === idResa)?.linkedTo === _uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[_repas][0]}));

    let action = "add";

    if (_resa === "delete") {

      action = "delete";
      if (ctx.data[_date].reservation[_uid][_repas][0].createdLocally) {
        //cancel
        map["reservation." + _uid + "." + _repas] = [{ status: "canceled", homeDelivery: false }];
      } else {
        map["reservation." + _uid + "." + _repas] = firestore.FieldValue.delete();
        guestResa.forEach(element => {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();
        })
      }
    } else {
      if (_resa[0].status === "canceled") delete _resa[0].status;
      if (_resa[0].createdLocally === true) delete _resa[0].createdLocally;

            // Gestion des invités

      // 1: Mise à jour des commandes
      _resa.forEach(element => {
        // Cas resa invité
        if(element.uid) {
          if(!map["reservation." + element.uid + "." + _repas]) map["reservation." + element.uid + "." + _repas] = [];
          map["reservation." + element.uid + "." + _repas].push(element)
        } 
        // Cas resa résident
        else {
          if(!map["reservation." + _uid + "." + _repas]) map["reservation." + _uid + "." + _repas] = [];
          map["reservation." + _uid + "." + _repas].push(element)
        }
      })

      // 2: Suppression de commande invités
      guestResa.forEach(element => {
        if(!map["reservation." + element.uid + "." + _repas]) {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();;
        }
      })
    }

    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .collection("menu")
      .doc(_date)
      .update(map);


    sendNotifToMajor({
      ui,
      data: {
        type: "menu", // ["animation", "menu", "service", ...] 
        action: action, // ["add", "update", "delete"]
        data: {
          userId: _uid, // l'id de l'utilisateur qui a fait l'action 
          date: moment(_date).startOf("day").toDate(),
          name: _repas, // repas, animation name, prestataire
          homeDelivery: _resa[0]?.homeDelivery ?? false,
          quantity: _resa === "delete" ? 0 : _resa?.length,
        }
      },
    });

    if (_resa === "delete") {
      toast.success("Annulation Réussie");
    } else {
      toast.success("Réservation Réussie");
    }
  };



  ctx.updateMenu = updateMenu;
  //console.log(ctx);

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
};
const useMenu = () => {
  const contexte = useContext(Context);

  if (!contexte) throw new Error("Le contexte n'est pas défini, il n'est probablement pas dans un provider !");

  return contexte;
};
export default useMenu;
export { Provider, Context };